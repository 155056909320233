@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: -webkit-fill-available;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Poppins';
}

::selection {
  background: #db26241A;
}

::-webkit-details-marker {
  display: none;
}

[data-rmiz-modal-overlay] {
  min-height: 100vh;
  min-height: c-webkit-fill-available;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .5rem center;
  background-size: 1em;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;

  >div {
    height: 100% !important;
  }
}